import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"

import SocialIcons from "../SocialIcons/SocialIcons"
import Button from "../Button/Button"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

interface PageData {
  address: string
  phone: string
  phoneurl: string
  fax: string
  faxurl: string
  socialLinks: any
}

const ContactInformation: React.FC<PageData> = ({
  address,
  phone,
  phoneurl,
  fax,
  faxurl,
  socialLinks,
}) => {
  const phoneTitle = "Phone"
  const faxTitle = "Fax"
  const [text, setText] = useState({ phoneTitle, faxTitle })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ phoneTitle, faxTitle })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([phoneTitle, faxTitle])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <section
      css={tw`flex justify-center lg:justify-start items-center lg:items-start font-sans`}
    >
      <div
        css={[
          tw`flex flex-col h-auto justify-center lg:justify-start items-center lg:items-start w-full top-0  bg-cover bg-no-repeat font-sans leading-normal pt-12`,
        ]}
      >
        <div
          css={tw`text-lg flex flex-col justify-center items-start text-left mt-12`}
        >
          <span dangerouslySetInnerHTML={{ __html: address }} />
          <span css={tw`pt-8`}>
            {text.phoneTitle}{" "}
            <a css={tw`text-blue-250`} href={phoneurl}>
              {phone}
            </a>
          </span>
          <span css={tw`pt-1`}>
            {text.faxTitle}{" "}
            <a css={tw`text-blue-250`} href={faxurl}>
              {fax}
            </a>
          </span>
        </div>
        <div
          css={tw`flex justify-center lg:justify-start items-center lg:items-start w-full`}
        >
          <div
            css={tw`w-3/5 justify-center lg:justify-start items-center lg:items-start`}
          >
            <Button
              ariaLabel={`view on google maps`}
              styles={tw`mt-12 rounded-full w-full justify-center`}
              wrapperStyles={tw`w-full flex justify-center`}
              link={"https://goo.gl/maps/tPNsJrMp7Zc3ogSdA"}
              text={"View on Google Maps"}
              target="_blank"
            />
          </div>
        </div>
        <div css={tw`flex justify-center items-center mt-4`}>
          <SocialIcons
            socialLinks={socialLinks}
            ulStyling={tw`flex flex-row justify-center mt-10 mb-20 xl:mb-10`}
            liStyling={tw`pl-7 first-of-type:pl-0`}
          />
        </div>
      </div>
    </section>
  )
}

export default ContactInformation
