import React, { useState, useContext, useEffect, useRef } from "react"
import { submitContactForm } from "./ContactSubmit"
import tw from "twin.macro"
import { LanguageContext } from "../../context/Language"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import TranslateClient from "../../clients/TranslateClient"

import { Alert, Button, Input, TextArea } from "../Form"
import ReCAPTCHA from "react-google-recaptcha"

interface Props {
  resource: string
  headingStyle?: any
  formTitle?: string
}

const ContactForm: React.FC<Props> = ({
  resource,
  headingStyle,
  formTitle,
  ...remainingProps
}) => {
  const heading = formTitle ? formTitle : "Get In Touch"
  const namePlaceholder = "Name (Req)"
  const businessNamePlaceholder = "Business Name"
  const emailPlaceholder = "Email (Req)"
  const phonePlaceholder = "Phone"
  const messagePlaceholder = "Message"
  const [text, setText] = useState({
    heading,
    namePlaceholder,
    businessNamePlaceholder,
    emailPlaceholder,
    phonePlaceholder,
    messagePlaceholder,
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        heading,
        namePlaceholder,
        businessNamePlaceholder,
        emailPlaceholder,
        phonePlaceholder,
        messagePlaceholder,
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          heading,
          namePlaceholder,
          businessNamePlaceholder,
          emailPlaceholder,
          phonePlaceholder,
          messagePlaceholder,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  const recaptchaRef = useRef(null)
  const [name, setName] = useState<string>("")
  const [businessName, setBusinessName] = useState<string>("")
  const [emailAddress, setEmailAddress] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [message, setMessage] = useState<string>("")

  const [error, setError] = useState<string>("")
  const [success, setSuccess] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const inputWrapperStyles = tw`my-2`
  const inputStyles = tw`w-full`

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const onSubmit = async (e: any) => {
    e?.preventDefault()
    setSubmitting(true)
    setSuccess(false)
    setError("")
    if (!validateEmail(emailAddress.trim())) {
      setError("Invalid email address")
      setSubmitting(false)
      return
    }
    const recaptchaValue = recaptchaRef?.current
      ? recaptchaRef.current?.getValue()
      : null
    const success = await submitContactForm({
      email: emailAddress.trim(),
      name: name.trim(),
      form_type: resource.trim(),
      message: message.trim(),
      phone: phoneNumber.trim(),
      business_name: businessName.trim(),
      user_captcha_token: recaptchaValue,
      signature: "",
    })
    setSuccess(success)
    setSubmitting(false)
    if (!success) {
      setError("Uh oh! Something went wrong.")
    } else {
      setName("")
      setBusinessName("")
      setEmailAddress("")
      setPhoneNumber("")
      setMessage("")
    }
  }

  return (
    <section {...remainingProps}>
      <div
        css={[
          tw`flex flex-col h-auto justify-center lg:justify-start items-center lg:items-start w-full top-0  bg-cover bg-no-repeat font-sans leading-normal pt-8 px-4`,
        ]}
      >
        <div>
          <h2 css={[tw`text-3xl font-extrabold text-left`, headingStyle]}>
            {text?.heading}
          </h2>
        </div>
        <div css={[tw`text-lg w-5/6 lg:w-full`]}>
          {error && (
            <Alert message={error} type="error" wrapperStyles={tw`mb-2`} />
          )}
          {success && !error && (
            <Alert
              message="Your request has been received!"
              type="success"
              wrapperStyles={tw`mb-2`}
            />
          )}
          <form>
            <Input
              wrapperStyles={inputWrapperStyles}
              styles={inputStyles}
              css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-1`}
              placeholder={text?.namePlaceholder}
              onChange={e => setName(e.target.value)}
              required
              value={name}
            />
            <Input
              wrapperStyles={inputWrapperStyles}
              styles={inputStyles}
              css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-1`}
              placeholder={text?.businessNamePlaceholder}
              onChange={e => setBusinessName(e.target.value)}
              required
              value={businessName}
            />
            <Input
              wrapperStyles={inputWrapperStyles}
              styles={inputStyles}
              css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-1`}
              placeholder={text?.emailPlaceholder}
              onChange={e => setEmailAddress(e.target.value)}
              required
              value={emailAddress}
            />
            <Input
              wrapperStyles={inputWrapperStyles}
              styles={inputStyles}
              css={tw`bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-1`}
              placeholder={text?.phonePlaceholder}
              onChange={e => setPhoneNumber(e.target.value)}
              required
              value={phoneNumber}
            />
            <TextArea
              wrapperStyles={inputWrapperStyles}
              styles={inputStyles}
              placeholder={text?.messagePlaceholder}
              rows={6}
              onChange={e => setMessage(e.target.value)}
              required
              value={message}
            />
            <div
              css={tw`md:flex justify-between md:items-center lg:flex-wrap 25xl:flex-nowrap 25xl:justify-between`}
            >
              <ReCAPTCHA
                sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
                ref={recaptchaRef}
              />
              <Button
                text="SEND"
                styles={tw`mt-3 rounded-full min-w-full md:min-w-[250px]`}
                css={tw`font-extraBold bg-lightGray rounded p-2.5 placeholder-darkGray font-sans w-full my-1 h-48 resize-none`}
                disabled={submitting}
                wrapperStyles={tw`w-full flex justify-center md:justify-end lg:justify-start 25xl:justify-end`}
                onClick={e => onSubmit(e)}
                loading={submitting}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
