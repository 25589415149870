import React from "react"
import { graphql, PageProps } from "gatsby"

import { TemplateProps } from "../templates"
import { ContactUsPageData } from "../../components/Contact/contact.d"
import ImageHero from "../../components/Hero/ImageHero"
import Contact from "../../components/Contact/contact"
import { SEO } from "../../components/SEO"

const ContactUsPage: React.FC<PageProps> = ({
  data,
  location,
}: TemplateProps<ContactUsPageData>) => {
  const { title, content, template, featuredImage } = data.wpPage
  const { allWp } = data

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <ImageHero image={featuredImage} />
      <Contact
        title={title}
        content={content}
        template={template}
        socialLinks={allWp.nodes[0].siteSettings.socialLinks}
      />
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      slug
      template {
        ... on WpTemplate_ContactUs {
          templateName
          contactUsPage {
            fieldGroupName
            contactInformation {
              address
              fieldGroupName
              headline
              phoneLink {
                target
                title
                url
              }
              faxLink {
                target
                title
                url
              }
            }
            directorySection {
              description
              directoryEntries {
                description
                downloadFile {
                  localFile {
                    publicURL
                  }
                }
                fieldGroupName
                title
              }
              fieldGroupName
              headline
            }
          }
        }
      }
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      title
    }
    allWp {
      nodes {
        siteSettings {
          socialLinks {
            facebook
            instagram
            twitter
          }
        }
      }
    }
  }
`

export default ContactUsPage
