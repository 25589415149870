import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import ContactForm from "./ContactForm"
import ContactInformation from "./ContactInformation"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

interface PageData {
  title: string
  content: string
  template: any
  socialLinks: any
}

const Contact: React.FC<PageData> = ({
  title,
  content,
  template,
  socialLinks,
}) => {
  const sectionHeading = template?.contactUsPage?.contactInformation?.headline
  const [text, setText] = useState({ title, content, sectionHeading })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, content, sectionHeading })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          title,
          content,
          sectionHeading,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <section
      css={tw`bg-cover bg-no-repeat`}
      style={{
        backgroundImage: "url('/imageHeavyTemplate.jpg')",
        backgroundPositionX: "50%",
        backgroundPositionY: "top -200px",
      }}
    >
      <div
        css={[
          tw`flex flex-col h-auto justify-center items-center w-screen top-0 font-sans leading-normal`,
        ]}
      >
        <div css={[tw`flex justify-center items-center pt-20 pb-6`]}>
          <h2 css={[tw`text-center text-3xl font-extrabold`]}>{text?.title}</h2>
        </div>
        <div css={tw`text-lg flex justify-center items-center pb-12`}>
          <span
            css={tw`w-4/5 lg:w-2/3 text-center`}
            dangerouslySetInnerHTML={{ __html: text?.content }}
          />
        </div>
      </div>
      {template && (
        <section
          css={tw`flex flex-col font-sans justify-center items-center mt-4 w-full`}
        >
          <div css={[tw`flex justify-center items-center w-full`]}>
            <SectionHeading>{text?.sectionHeading}</SectionHeading>
          </div>
          <div
            css={tw`flex flex-row flex-wrap justify-center items-center lg:items-start w-full lg:w-11/12`}
          >
            <div
              css={tw`w-full lg:w-2/5 justify-center lg:justify-start items-center`}
            >
              <ContactInformation
                address={template?.contactUsPage?.contactInformation?.address}
                phone={
                  template?.contactUsPage?.contactInformation?.phoneLink?.title
                }
                phoneurl={
                  template?.contactUsPage?.contactInformation?.phoneLink?.url
                }
                fax={
                  template?.contactUsPage?.contactInformation?.faxLink?.title
                }
                faxurl={
                  template?.contactUsPage?.contactInformation?.faxLink?.url
                }
                socialLinks={socialLinks}
              />
            </div>
            <div css={tw`w-full lg:w-2/5`}>
              <ContactForm resource={"contact"} />
            </div>
          </div>
        </section>
      )}
    </section>
  )
}

export default Contact
